  .home-right{
    height: 100%;
  }

  .home-right-main{
    height: 100%;
  }

  .home-right-main-img{
      max-width: 100%;
      max-height: 65vh;
      height: auto;
      margin: auto;
      display: block;
  }

  .home-right-footer{
     width: 100%;
  }
